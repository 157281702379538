import * as React from 'react'
import Header from '../components/header'
import { Container, Row, Col } from 'react-bootstrap'
import { withPrefix } from 'gatsby'

const InstructorPage = () => {
  return (
    <>
    <Header activePage="Authors" />
    <Container>
        <h1 style={{paddingTop: "15px"}}>Authors</h1>
        <Row style={{padding: "10px"}}>
          <Col xs={3}>
            <img width="250" style={{marginRight: "auto",marginLeft: "auto"} } src={withPrefix('../mf.jpeg')} />
            </Col>
            <Col xs={6} style={{textAlign: "justify"}}>
            <b>Mathias Funk</b> <p>Associate Professor in the department of Industrial Design and is interested in design theory and processes for systems, designing systems for musical expression, and designing with data. He has a background in computer science and electrical engineering. His main research interest is how designers can design with and for data and systems behavior. He works primarily on design tools and the layers between engineering and modern industrial design. He is the responsible lecturer for the Data-enabled Design course in the Industrial Design Master program of Eindhoven University of Technology.</p>
            </Col>
          </Row>
          <Row style={{padding: "10px"}}>
            <Col xs={3}>
            <img width="250" style={{marginRight: "auto",marginLeft: "auto"} } src={withPrefix('../rn.png')} />
            </Col>
            <Col xs={6} style={{textAlign: "justify"}}>
            <b>Renee Noortman</b> <p>Doctoral researcher in the department of Industrial Design at Eindhoven University of Technology. She has a background in interaction design and user-centered design. Her research is focused on expanding the data-enabled design methodology by using storytelling to give meaning to data and future data practices. She works on multiple projects around envisioning the future of healthcare through speculative design, design fction and data-enabled storytelling, in collaboration with Philips Experience Design and various hospitals. She is one of the lecturers in the Data-enabled Design Master course, providing lectures and workshops on the user-centered aspects of the methodology and how to meaningfully collect and use qualitative data.</p>
            </Col>
          </Row>
          <Row style={{padding: "10px"}}>
            <Col xs={3}>
            <img width="250" style={{marginRight: "auto",marginLeft: "auto"} } src={withPrefix('../sr.jpeg')} />
            </Col>
            <Col xs={6} style={{textAlign: "justify"}}>
            <b>Sujithra Raviselvam</b> <p>is a Postdoctoral Researcher affiliated with the Future Everyday group in the Department of Industrial Design at the Eindhoven University of Technology (TU/e). She received her PhD in Engineering Product Development from the Singapore University of Technology and Design (SUTD). Her expertise is in adapting Design Methods to attain inclusive and creative new perspectives for products, services, and systems design. She has collaborated with industry partners and researchers from various fields and applied her research in domains like healthcare, cyber security, assistive technology, and general education. During her doctoral studies, she also collaborated with the Ideation Lab at the Massachusetts Institute of Technology (MIT) as a Visiting Scholar. She has facilitated Design Innovation workshops and mentored student teams in Singapore, the United States, Saudi Arabia, and India.</p>
            </Col>
          </Row>
        <Row style={{padding: "10px"}}>
          <Col xs={3}>
            <img width="250" style={{marginRight: "auto",marginLeft: "auto"} } src={withPrefix('../pl.jpg')} />
          </Col>
          <Col xs={6} style={{textAlign: "justify"}}>
            <b>Peter Lovei</b>
            <p>Data Designer at Philips Experience Design and PhD candidate at the Industrial Design department of Eindhoven University of Technology (TU/e). His main research interest lies in the intersection of computer science and design research. He is applying and improving the data-enabled design process by developing scalable and modular components that can be applied across a wide range of care pathways. He is achieving this via his research through design projects around the topics of sleep, pregnancy, elderly care, improvement of clinical care for cardiovascular patients, and patients who underwent a bariatric surgery. He is one of the lecturers in the Data-enabled Design Master course. In his lectures he is explaining the technical background of DED and how to scale the approach in larger studies.</p>
          </Col>
          </Row>
         
          

    </Container>
    </>
  )
}
export default InstructorPage